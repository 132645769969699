
// zentrale style sheet datei in root
// die lokale material icons läd
//

// @font-face {
//   font-family: 'Material Icons';
//   font-style: normal;
//   font-weight: 400;
//   src: url('app/fonts/iconfont/MaterialIcons-Regular.woff2') format('woff2');
// }


@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
         local('MaterialIcons-Regular'),
         url('app/fonts/iconsfont/MaterialIconsOutlined-Regular.otf') format('opentype'),
         url('app/fonts/iconsfont/MaterialIconsRound-Regular.otf') format('opentype'),
         url('app/fonts/iconsfont/MaterialIconsSharp-Regular.otf') format('opentype'),
         url('app/fonts/iconsfont/MaterialIconsTwoTone-Regular.otf') format('opentype'),
         url('app/fonts/iconsfont/MaterialIcons-Regular.ttf') format('truetype');
  }

$primary-background-color: var(--primary-background-color);
$primary-text-color: var(--primary-text-color);

$highlight-background-color: var(--highlight-background-color);
$highlight-text-color: var(--highlight-text-color);

$button-background-color: var(--button-background-color);
$button-text-color: var(--button-text-color);

$info-button-text-color: var(--info-button-text-color);
$add-button-text-color: var(--add-button-text-color);
$substract-button-text-color: var(--substract-button-text-color);

$step-edit-background-color: var(--step-edit-background-color);
$step-edit-text-color: var(--step-edit-text-color);

$input-highlight-color: var(--input-highlight-color);

.mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
    stroke: $primary-background-color !important;
}

.mat-mdc-tooltip{
    font-size: small !important;
}

// color for single Select option
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) 
{
    color: $input-highlight-color !important;
}


.mat-mdc-select-arrow{
    color: $input-highlight-color !important;
}


.mdc-floating-label--float-above
{
    color: $input-highlight-color !important;
}

.mat-calendar-body-cell:hover
{
    background-color: $highlight-background-color !important;
    color: $highlight-text-color !important;
}
.mat-calendar-body-cell-content{
    background-color: rgba(0,0,0,0) !important;
}
.mat-calendar-body-cell-content:hover {
    color: $highlight-text-color !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background
{
    background-color: $highlight-background-color !important;
    color: $highlight-text-color !important;
    border-color: $highlight-background-color !important;
}


.mdc-text-field--filled .mdc-line-ripple::after
{
    border-bottom-color: $input-highlight-color !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: $highlight-background-color !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: $highlight-background-color !important;
}


// color for multiselect checkboxes
.mat-primary .mat-pseudo-checkbox-checked
{
    background-color: $highlight-background-color !important;
}

.mat-mdc-form-field-error
{
    color: $input-highlight-color !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label
{
    color: $input-highlight-color !important;
}

.primary-colors 
{
    background-color: $primary-background-color !important;
    color: $primary-text-color !important;
}

.highlight-colors
{
    background-color: $highlight-background-color !important;
    color: $highlight-text-color !important;
}

.button-colors
{
    background-color: $button-background-color !important;
    color: $button-text-color !important;
}

.info-button-colors
{
    color: $info-button-text-color !important;
}

.add-button-colors
{
    color: $add-button-text-color !important;
}

.substract-button-colors
{
    color: $substract-button-text-color !important;
}

.step-edit-colors
{
    background-color: $step-edit-background-color !important;
    color: $step-edit-text-color !important;
}

button.mat-mdc-raised-button{
    background-color: $button-background-color !important;
    color: $button-text-color !important;
}

button.mat-mdc-mini-fab{
    background-color: $button-background-color !important;
    color: $button-text-color !important;
}

button.mat-mdc-raised-button:disabled,
button.mat-raised-button[disabled]
{
    background-color: grey !important;
    color: white !important;
}

.mat-step-icon-selected
{
    background-color: $highlight-background-color !important;
    color: $highlight-text-color !important;
}

.mat-step-icon-state-done,
.mat-step-icon-state-edit
{
    background-color: $step-edit-background-color !important;
    color: $step-edit-text-color !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-outer-circle
{
    border-color: $highlight-background-color !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-inner-circle
{
    background-color: $highlight-background-color !important;
    color: $highlight-text-color !important;
}

.mat-calendar-body-selected
{
    background-color: $highlight-background-color !important;
    color: $highlight-text-color !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-label 
{
    color: $input-highlight-color !important;
}
  
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-ripple 
{
   background-color: $input-highlight-color !important;;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background
{
    background-color: $highlight-background-color !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checkmark
{
    fill: $highlight-text-color !important;
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

// weitere globale styles koennen hier definiert werden.
.center
{
   margin: 0 auto;
   width: 50%;
   min-width: 300px;
}
.mat-mdc-form-field {

   width:100%
}
.mat-mdc-form-field-error{
    height:1.2em;
}

a
{
    color: $primary-background-color !important;
}